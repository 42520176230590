import { useMutation } from '@tanstack/react-query';
import {
	Button,
	Card,
	Form,
	Input,
	InputNumber,
	message,
	Popconfirm,
	Table,
} from 'antd';
import { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useState } from 'react';
import StockTable from './component/StockTable';
import { getStockTableSetting } from './component/getStockTableSetting';
import { useNavigate } from 'react-router';
import { honoClient } from './honoClient';
import { useTitle } from 'ahooks';
import { isNil, uniqBy } from 'lodash-es';
import { roundFix4 } from './TransactionEdit';
import { roundFix2 } from './roundFix2';
import Nzh from 'nzh';
extend(isBetween);

export default function Sell() {
	useTitle('仓库出货');
	const [form] = Form.useForm();
	const clientName = Form.useWatch('client', form);
	const navigate = useNavigate();

	useTitle('送货单');

	return (
		<div className="flex flex-col">
			<Card>
				<StockTable
					onDelivery={(c) => {
						const nowStock = form.getFieldValue(['stock']);
						form.setFieldValue(
							['stock'],
							!isNil(nowStock) ? uniqBy([...nowStock, ...c], 'uuid') : c,
						);
					}}
				/>
			</Card>
			<Card title="仓库出货" className="">
				<Form
					form={form}
					onFinish={async (v) => {
						const res = await honoClient.sell.$post({
							json: {
								stock: v.stock.map((a) => {
									a.cubic = Number.parseFloat(a.cubic);
									a.unitPrice = Number.parseFloat(a.unitPrice);

									return a;
								}),
								client: clientName,
							},
						});

						const opRes = await res.json();

						if (opRes.code === 0) {
							message.success('生成成功');
							navigate('/wood/transaction');
						}
					}}
					onFinishFailed={(v) => {
						console.log('onFinishFailed', v);
					}}
				>
					<Form.Item
						label="客户"
						name="client"
						rules={[{ required: true, message: '请输入' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item shouldUpdate>
						{({ getFieldValue }) => {
							const mStock = getFieldValue('stock');

							const totalCubic = roundFix4(
								mStock?.reduce((p, c) => {
									return p + Number.parseFloat(c.cubic);
								}, 0) ?? 0,
							);

							const totalPrice = roundFix2(
								mStock?.reduce((p, c) => {
									return (
										p +
										Number.parseFloat(c.cubic) * Number.parseFloat(c.unitPrice)
									);
								}, 0) ?? 0,
							);

							return (
								<>
									<h3>货品</h3>
									<Table
										size="small"
										dataSource={mStock}
										{...getStockTableSetting([
											{
												title: 'cubic 确认',
												fixed: 'right',
												key: 'cubic',
												width: 120,
												dataIndex: 'cubic',
												render: (value, record, index) => {
													return (
														<>
															<Form.Item
																name={['stock', index, 'uuid']}
																initialValue={record.uuid}
																required
																hidden
															>
																<InputNumber />
															</Form.Item>
															<Form.Item
																initialValue={Number(value)}
																style={{ margin: 0 }}
																name={['stock', index, 'cubic']}
																rules={[
																	{
																		required: true,
																		message: '请输入',
																	},
																]}
																required
															>
																<InputNumber />
															</Form.Item>
														</>
													);
												},
											},
											{
												title: 'unit price',
												fixed: 'right',
												key: 'unitPrice',
												width: 120,
												dataIndex: 'unitPrice',
												render: (value, record, index) => {
													return (
														<Form.Item
															initialValue={value}
															style={{ margin: 0 }}
															name={['stock', index, 'unitPrice']}
															rules={[
																{
																	required: true,
																	message: '请输入',
																},
															]}
															required
														>
															<InputNumber />
														</Form.Item>
													);
												},
											},

											{
												title: '操作',
												fixed: 'right',
												key: 'action',
												render: (_, record) => (
													<>
														<a
															// biome-ignore lint/a11y/useValidAnchor: <explanation>
															onClick={() => {
																form.setFieldValue(
																	['stock'],
																	mStock.filter(
																		(a: { uuid: string }) =>
																			a?.uuid !== record.uuid,
																	),
																);
															}}
														>
															删除
														</a>
													</>
												),
											},
										])}
										pagination={false}
									/>

									<div className="flex flex-row gap-2 justify-between">
										<div className="grid grid-cols-2 gap-3 my-3">
											<div>总平方数</div>
											<div>{totalCubic}</div>
											<div>总价格(小写)</div>
											<div>
												{Number.isNaN(totalPrice) ? '单价缺失' : totalPrice}
											</div>

											<div>总价格(大写)</div>
											<div>
												{Number.isNaN(totalPrice)
													? '单价缺失'
													: Nzh.cn.encodeB(totalPrice, {})}
											</div>

											<div>件数</div>
											<div>{mStock?.length ?? 0}</div>
										</div>
										<div className="flex flex-row items-center gap-2">
											<Form.Item label="批量设置单价" className="">
												<Form.Item noStyle name={'BulkSettingUnitPrice'}>
													<InputNumber />
												</Form.Item>

												<Button
													className="mx-2"
													size="small"
													onClick={() => {
														const unitPrice = form.getFieldValue(
															'BulkSettingUnitPrice',
														);
														const mStock = form.getFieldValue('stock');

														form.setFieldValue(
															'stock',
															mStock.map((i) => {
																i.unitPrice = unitPrice;
																return i;
															}),
														);
													}}
												>
													确认设置
												</Button>
											</Form.Item>
										</div>
									</div>

									<Popconfirm
										title={'确认以上货品吗'}
										onConfirm={async () => {
											form.submit();
										}}
									>
										<Button disabled={(mStock?.length ?? 0) === 0}>
											生成送货单
										</Button>
									</Popconfirm>
								</>
							);
						}}
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
}
